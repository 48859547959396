import { createActionGroup, emptyProps, props } from '@ngrx/store';

export const QuoteActions = createActionGroup({
  source: 'Quote',
  events: {
    'Load Quotes': emptyProps(),
    'Load Quotes Success': props<{ data: unknown }>(),
    'Load Quotes Failure': props<{ error: unknown }>(),
  }
});
