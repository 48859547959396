import { createReducer, on } from '@ngrx/store';
import { QuoteActions } from './quote.actions';

export const quoteFeatureKey = 'quote';

export interface State {
  data: unknown;
}

export const initialState: State = {
  data: null,
};

export const reducer = createReducer(initialState, on(
  QuoteActions.loadQuotesSuccess,
  (state, action) => {
    return {
      ...state,
      data: action.data
    }
  }
));
